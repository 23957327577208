import React, { useState } from "react";
import { useRouter } from "next/router";

const PasswordEntryPage = () => {
  const [password, setPassword] = useState("");
  const router = useRouter();

  const handleSubmit = () => {
    if (password === "IwantSumJsonCrack") {
      document.cookie = "wantsCrack=true; path=/; Secure; SameSite=Lax";
      router.push("/");
    } else {
      alert("Incorrect password");
    }
  };

  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <div className="w-60">
        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
          Whant sum crack?
        </label>
        <div className="mt-2">
          <input
            type="password"
            name="password"
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className="bg-white block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-600 sm:text-sm sm:leading-6"
            placeholder="Yes sir! Give me sum good crack!"
          />
        </div>
        <button
          type="button"
          onClick={handleSubmit}
          className="mt-8 rounded-md bg-zinc-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
        >
          Button text
        </button>
      </div>
    </div>
  );
};

export default PasswordEntryPage;
